import {
    Box,
    Flex,
    SegmentedControl,
    Title,
    useMantineTheme,
} from "@mantine/core";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { useFeatures } from "flagged";
import { useEffect } from "react";
import MetadataDocuments from "src/components/MetadataDocuments";
import { chatVectorIndex, ChatVectorIndexShorthand, FSR, SF } from "src/db";

const titles: Record<ChatVectorIndexShorthand, string> = {
    fsr: "Field Service Reports",
    sf: "Salesforce Cases",
    drawings: "Engineering Drawings",
    rds: "RDS Reports",
    mindmaps: "Mindmaps",
    combined: "Combined",
    emails: "Emails",
    uinfo: "SGT Useful Info"
};

export const DocumentsRoute = ({
    index,
    setSelectedIndex,
}: {
    index: ChatVectorIndexShorthand,
    setSelectedIndex: React.Dispatch<React.SetStateAction<ChatVectorIndexShorthand>>
}) => {
    const navigate = useNavigate();
    const theme = useMantineTheme();

    useEffect(() => {
        if (window.location.hash === "#/documents")
            navigate({ to: `/documents?tab=${index}` });
    }, [index]);

    const features = useFeatures();

    return (
        <Flex p="xl" direction="column" gap="md">
            <Flex justify="space-between">
                <Title lh={2} c="purple.8" order={2} transform="uppercase">
                    {titles[index]}
                </Title>
                <Box mt="xs">
                    <SegmentedControl
                        color={theme.colors.purple[7]}
                        styles={{
                            label: {
                                fontWeight: "bold",
                            },
                        }}
                        fullWidth
                        value={index}
                        onChange={async (value: typeof SF | typeof FSR) => {
                            setSelectedIndex(value);
                            navigate({ to: `/documents?tab=${value}` })
                        }}
                        data={chatVectorIndex
                            .filter((index) => Boolean(features[index.feature]))
                            .filter(item => item.shorthand !== 'combined' && item.shorthand !== 'emails')
                            .map(item => ({
                                label: item.label,
                                value: item.shorthand,
                            }))}
                    />
                </Box>
            </Flex>
            <Box>
                <MetadataDocuments index={index} />
            </Box>
        </Flex>
    );
};

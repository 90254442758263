import { useAccount, useMsal } from "@azure/msal-react";
import { useMemo, useState, useRef, useEffect } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_ColumnFilterFnsState,
} from 'mantine-react-table';
import { Modal, TextInput, Button, Stack, MultiSelect, Box, Textarea, Flex, Title } from '@mantine/core';
import { useInfiniteQuery, useQuery } from "react-query";
import { getAPI , postAPI} from 'src/utils/fetch';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { useAdmin } from "src/utils/useAdmin";


export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export type User = {
  id: string;
  org_code: string;
  email: string;
  roles: string[];
}

type UserApiResponse = {
  data: Array<User>;
};

interface QueryParams {
  columnFilterFns: MRT_ColumnFilterFnsState;
  columnFilters: MRT_ColumnFiltersState;
  globalFilter: string;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
}

interface MutationParams {
  data: Partial<User>;
}

interface Role {
  id: string;
  description: string;
  permissions: string[];
}

interface RolesResponse {
  data: Role[];
}

// Create a custom hook for user operations
const useUserOperations = () => {
  const isAdmin = useAdmin();
  const { instance } = useMsal();
  const account = useAccount();
  const apiKey = account?.idToken;

  // GET operation
  const getUsers = ({
    columnFilterFns,
    columnFilters,
    globalFilter,
    sorting,
    pagination,
  }: QueryParams) => {
    if (!isAdmin) {
      return {
        data: [],
        isLoading: false,
        error: new Error("Unauthorized"),
      };
    }

    const fetchURL = new URL('/v1/auth/users', BACKEND_URL);
    fetchURL.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
    fetchURL.searchParams.set('size', `${pagination.pageSize}`);
    fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    fetchURL.searchParams.set('filterModes', JSON.stringify(columnFilterFns ?? {}));
    fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
    fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

    return useInfiniteQuery<UserApiResponse>({
      queryKey: ['users', fetchURL.href],
      queryFn: async () => {
        const response = await getAPI({ url: fetchURL.href, apiKey, instance });
        const data = await response.json();
        return data;
      },
      keepPreviousData: true,
      enabled: isAdmin,
    });
  };

  // POST operation
  const updateUser = async ({ data }: MutationParams) => {
    try {
      const response = await postAPI({
        url: `${BACKEND_URL}/v1/auth/users`,
        apiKey,
        instance,
        payload: data,
      });

      if (!response.ok) {
        throw new Error('Failed to update user');
      }

      return response.json();
    } catch (error) {
      throw error;
    }
  };

  return {
    getUsers,
    updateUser,
  };
};

const useRoles = () => {
  const { instance } = useMsal();
  const account = useAccount();
  const apiKey = account?.idToken;

  return useQuery<Role[], Error>({
    queryKey: ['roles'],
    queryFn: async () => {
      const response = await getAPI({ 
        url: `${BACKEND_URL}/v1/auth/roles`, 
        apiKey, 
        instance 
      });
      const jsonData = await response.json();
      return jsonData;
    },
  });
};

const ManageUsers = () => {
  const { instance } = useMsal();
  const account = useAccount();
  const { data: roles = [], isLoading: isLoadingRoles, refetch: fetchRoles } = useRoles();
  const [roleOptions, setRoleOptions] = useState<{ value: string; label: string; }[]>([]);

 

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        accessorFn: (row) => (row ? row.id?.toString() : '') ?? '',
      },
      {
        accessorKey: 'org_code',
        header: 'Org Code',
        accessorFn: (row) => (row ? row.org_code?.toString() : '') ?? '',
      },
      {
        accessorKey: 'email',
        header: 'Email',
        accessorFn: (row) => (row ? row.email?.toString() : '') ?? '',
      },
      {
        accessorKey: 'roles',
        header: 'Roles',
        accessorFn: (row) => (row ? row.roles?.join(', ') : '') ?? '',
      },
    ],
    [],
  );

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [columnFilterFns, setColumnFilterFns] = 
    useState<MRT_ColumnFilterFnsState>(
      Object.fromEntries(
        columns.map(({ accessorKey }) => [accessorKey, 'contains']),
      ),
    ); //default to "contains" for all columns
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { getUsers, updateUser } = useUserOperations();

  // Use the GET operation
  const {
    data,
    isLoading,
    refetch,
  } = getUsers({
    columnFilterFns,
    columnFilters,
    globalFilter,
    pagination,
    sorting,
  });
  const fetchedUsers = data?.pages.flatMap(page => Array.isArray(page) ? page : []) ?? [];
  const userRoles = new Set(fetchedUsers.flatMap(user => user.roles || []));
  const allRoles = [...new Set([...roles.map(r => r.id), ...userRoles])];

  
  useEffect(() => {
    if (allRoles.length > 0) {
      const roleOptions = allRoles.map(roleId => {
        const role = roles.find(r => r.id === roleId);
        return {
          value: roleId,
          label: role && role.description ? `${roleId} - ${role.description}` : roleId
        };
      });
      setRoleOptions(roleOptions);
    }
    }, [roles, fetchedUsers, allRoles]);


  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);

  const fetchMoreOnBottomReached = async (containerRefElement?: HTMLDivElement) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isRefetching && 
        data?.pages[data.pages.length - 1]?.data?.length === pagination.pageSize
      ) {
        setIsRefetching(true);
        await setPagination(old => ({
          ...old,
          pageIndex: old.pageIndex + 1,
        }));
        setIsRefetching(false);
      }
    }
  };

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
      org_code: '',
      roles: [] as string[],
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      org_code: (value) => (value.length < 1 ? 'Organization code is required' : null),
      roles: (value) => (value.length === 0 ? 'At least one role is required' : null),
    },
  });

  const handleOpenModal = (user: User) => {
    if (isLoadingRoles) {
      notifications.show({
        title: 'Loading',
        message: 'Please wait while roles are being loaded...',
        loading: true,
      });
      return;
    }

    if (roleOptions.length === 0) {
      fetchRoles();
    }

    setSelectedUser(user);
    form.setValues({
      email: user.email,
      org_code: user.org_code,
      roles: user.roles,
    });
    setModalOpened(true);
  };

  const handleSubmit = async (values: typeof form.values) => {
    setIsSubmitting(true);
    try {
      if (!selectedUser) {
        throw new Error('No user selected');
      }

      await updateUser({
        data: {
          id: selectedUser.id,
          email: selectedUser.email,
          org_code: selectedUser.org_code,
          roles: [...new Set([...values.roles])]
        },
      });

      notifications.show({
        title: 'Success',
        message: 'User roles updated successfully',
        color: 'green',
      });
      
      refetch();
      setModalOpened(false);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to update user roles',
        color: 'red',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [createRoleModalOpened, setCreateRoleModalOpened] = useState(false);
  const [isCreatingRole, setIsCreatingRole] = useState(false);

  const createRoleForm = useForm({
    initialValues: {
      id: '',
      description: '',
      permissions: [] as string[], // If you need to handle permissions
    },
    validate: {
      id: (value) => (!value ? 'Role ID is required' : null),
      description: (value) => (!value ? 'Description is required' : null),
    },
  });

  const handleCreateRole = async (values: typeof createRoleForm.values) => {
    setIsCreatingRole(true);
    try {
      const response = await postAPI({
        url: `${BACKEND_URL}/v1/auth/roles`,
        apiKey: account?.idToken,
        instance,
        payload: values,
      });

      if (!response.ok) {
        throw new Error('Failed to create role');
      }

      notifications.show({
        title: 'Success',
        message: 'Role created successfully',
        color: 'green',
      });

      // Clear the roles so they'll be fetched fresh next time
      setRoleOptions([]);
      setCreateRoleModalOpened(false);
      createRoleForm.reset();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to create role',
        color: 'red',
      });
    } finally {
      setIsCreatingRole(false);
    }
  };

  const table = useMantineReactTable({
    columns,
    data: fetchedUsers ?? [],
    enableColumnOrdering: true,
    enablePinning: true,
    enableBottomToolbar: false,
    renderToolbarAlertBannerContent: () => <></>,
    enableColumnResizing: true,
    enableFilters: true,
    enablePagination: false,
    enableRowNumbers: true,
    enableClickToCopy: false,
    getRowId: (row) => row.id,
    mantineToolbarAlertBannerProps: {
        style: {
            display: "none",
            background: "#f0f0f0",
            fontWeight: "bold",
        },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    enableSelectAll: false,
    selectAllMode: "page",
    enableGrouping: true,
    enableDensityToggle: false,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: '80vh' },
      onScroll: (event) =>
        fetchMoreOnBottomReached(event.currentTarget),
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => handleOpenModal(row.original),
      sx: { 
        cursor: isLoadingRoles ? 'wait' : 'pointer',
        opacity: isLoadingRoles ? 0.7 : 1,
      },
    }),
    state: {
      isLoading: isLoading || isLoadingRoles,
      showProgressBars: isRefetching,
      columnFilters,
      globalFilter,
      sorting,
    },
    initialState: {
        showGlobalFilter: true,
        density: "xs",
        showColumnFilters: true,
    },
  });

  return (
    <>
          <Flex p="xl" direction="column" gap="md">
            <Flex gap="md" align="center">
                <Title lh={2} c="purple.8" order={2} transform="uppercase">
                    Manage Users
                </Title>
                <Button
                  onClick={() => setCreateRoleModalOpened(true)}
                  variant="filled"
                >
                  Create New Role
                </Button>
            </Flex>
        </Flex>
    

      <MantineReactTable table={table} />
      
      <Modal
        opened={createRoleModalOpened}
        onClose={() => {
          setCreateRoleModalOpened(false);
          createRoleForm.reset();
        }}
        title="Create New Role"
        size="md"
      >
        <Box component="form" onSubmit={createRoleForm.onSubmit(handleCreateRole)}>
          <Stack spacing="md">
            <TextInput
              required
              label="Role ID"
              placeholder="e.g., admin_role"
              {...createRoleForm.getInputProps('id')}
            />
            
            <Textarea
              required
              label="Description"
              placeholder="Describe the purpose of this role"
              minRows={3}
              {...createRoleForm.getInputProps('description')}
            />

            {/* If you need to handle permissions, you can add a MultiSelect here */}
            
            <Button 
              type="submit"
              loading={isCreatingRole}
              fullWidth
            >
              Create Role
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setSelectedUser(null);
          form.reset();
        }}
        title="Update User Roles"
        size="md"
      >
        <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
          <Stack spacing="md">
            <TextInput
              required
              label="Email"
              placeholder="user@example.com"
              {...form.getInputProps('email')}
            />
            
            <TextInput
              required
              label="Organization Code"
              placeholder="Enter organization code"
              {...form.getInputProps('org_code')}
            />
            
            <MultiSelect
              required
              label="Roles"
              placeholder="Select roles"
              data={roleOptions}
              value={form.values.roles}
              onChange={(value) => {
                form.setFieldValue('roles', value);
              }}
              withinPortal
              searchable
              clearable
              nothingFound="No roles found"
              disabled={isLoadingRoles}
            />

            <Button 
              type="submit"
              loading={isSubmitting}
              fullWidth
            >
              Update
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};




export default ManageUsers;
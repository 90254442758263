import {
    Container, SegmentedControl, Space, Tooltip, useMantineTheme, Text,
    Box
} from "@mantine/core";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useMemo } from "react";
import {
    type ChatVectorIndexShorthand,
    FSR, SF, DRAWINGS, RDS, MINDMAPS, EMAILS, COMBINED,
    chatVectorIndex,
    db
} from "../db";
import { useChatId } from "../hooks/useChatId";

import { useNavigate } from "@tanstack/react-router";
import { useFeatures } from "flagged";
import { useTab } from "../contexts/tabContext";
import { ChatRouteChild } from "./ChatRoute";

const salesForceSuggestions = [
    "Shaft Speed Spread probe sensors are found to be out of expected range, can you provide the symptom classes  and step wise recommendations?",
    "What the most common problems associated to TCP rotors?",
    "List down details of problems and corresponding solution/recommendations for subject/problems related to IGV units?",
    "What are the causes of high delta differential pressure in the primary filter stage of an air intake system of an SGT-800?",
];

export const fieldServiceSuggestions = [
    "Provide the conclusion and recommendations to the customer MODEC Carioca for the commissiong activity on SGT-A35.",
    "What was the reason for shutdown in HI6074/03?",
    "List the reasons for outage issues for customer Gail having machine no. R375/02 from year 2019 to 2023.",
    "When was the last gas turbine changeout at North Rankin?",
];

export const mindMapSuggestions = [
    "What should I check if I have BOV drift ?",
    "describe the architecture of the rexroth type from Hydrostart",
    "What are the latest standard bolts to be used on BOV ?",
    "SGT100 Rexroth starter system setup",
];

export const emailSuggestions = [
    "What are the steps to investigate BOV issues?",
    "Could you share any information related to load accept and reject testing done to SGT400?",
    "How can the LPBOV be moved manually, and is there an instruction manual available?",
    "Are there any known load shedding issues with BD000069U02?"
]

export const drawingsSuggestions = [
    "Give me the drawings for machine number M.A217.01",
    "What are the drawings containing 'piping'?"
]


export const usefulInfoDocumentSuggestions = [
    "How do I zero the thrust setting for a Bently 3500?",
    "Please give me a summary of the purpose of PCS7",
    "What is a fast data logger?",
    "How do I debug a machine where the measured speed is doubling at a certain RPM?"
]

export const rdsDocumentSuggestions = [
    "summarize cases observed on BD000219U02",
]



const CHUNK_SIZE = 50;

const tabTooltips = {
    'field_service_reports': 'Search through field service reports to find historical maintenance data and technical recommendations.',
    'salesforce': 'Access Salesforce case histories and technical support documentation.',
    'combined': 'Search on Field Service Reports, Salesforce and SGT MindMaps',
    'support_emails': 'Search through email communications and correspondences.',
    'drawings': 'Search through engineering drawings and technical documentation.',
    'rds_reports': 'Access RDS reports and related documentation.',
    'useful_info': "Search through SGT 'Useful Info' documents",
    'mindmaps': 'Search through mind maps and knowledge graphs for technical information and troubleshooting guides.'
};

const useSelectedQuestions = (selectedTabShorthand: string) =>
    useMemo(() => {
        if (selectedTabShorthand === FSR) return fieldServiceSuggestions;
        if (selectedTabShorthand === SF) return salesForceSuggestions;
        if (selectedTabShorthand === MINDMAPS) return mindMapSuggestions;
        if (selectedTabShorthand === EMAILS) return emailSuggestions;
        if (selectedTabShorthand === "uinfo") return usefulInfoDocumentSuggestions;
        if (selectedTabShorthand === DRAWINGS) return drawingsSuggestions;
        if (selectedTabShorthand === RDS) return rdsDocumentSuggestions;
        if (selectedTabShorthand === COMBINED) return [...salesForceSuggestions.slice(0, 2), ...fieldServiceSuggestions.slice(0, 2)];
        return [];
    }, [selectedTabShorthand]);

export function ChatRoute() {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const chatId = useChatId();

    const chats = useLiveQuery(() =>
        db.chats.orderBy("createdAt").reverse().toArray(),
    );
    const { selectedTabShorthand, selectedTab, setSelectedTab } = useTab();

    useEffect(() => {
        if (window.location.hash === "#/") {
            navigate({ to: `/?tab=${selectedTab}` });
        }
    }, [selectedTab]);

    const selectedQuestions = useSelectedQuestions(selectedTabShorthand);

    const messages = useLiveQuery(() => {
        if (!chatId) return [];
        return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
    }, [chatId]);

    useEffect(() => {
        const foundChat = chats
            ?.filter((chat) => chat.id.startsWith(selectedTabShorthand))
            ?.find((chat) => chat.id === chatId);

        if (foundChat?.id && foundChat?.id !== chatId) {
            navigate({ to: `/chats/${foundChat.id}` });
        }
    }, [selectedTabShorthand, chatId]);

    const features = useFeatures();

    return (
        <>
            <Container size={features.USEFUL_INFO ? "md" : "sm"} mt="xl">
                <SegmentedControl
                    color={theme.colors.purple[7]}
                    styles={{
                        label: {
                            fontWeight: "bold",
                        },
                    }}
                    fullWidth
                    value={selectedTab}
                    onChange={async (value: ChatVectorIndexShorthand) => {
                        setSelectedTab(value);
                        navigate({ to: `/?tab=${value}` });
                    }}
                    data={chatVectorIndex
                        .filter((index) => Boolean(features[index.feature]))
                        .map((vector) => ({
                            value: vector.value[0],
                            label: (
                                tabTooltips[vector.value[0] as keyof typeof tabTooltips] ? (
                                    <Tooltip
                                        multiline
                                        label={tabTooltips[vector.value[0] as keyof typeof tabTooltips]}
                                        position="bottom-start"
                                        withinPortal={true}
                                        w={250}
                                        transitionProps={{ duration: 200 }}
                                        offset={15}
                                        radius={0}
                                        color="rgba(107, 106, 106, 0.93)"
                                        styles={{
                                            tooltip: {
                                                fontSize: '0.8rem',
                                                lineHeight: 1.2,
                                            }
                                        }}
                                    >
                                        <Text size="sm" sx={{ lineHeight: 1 }}>{vector.label}</Text>
                                    </Tooltip>
                                ) : (
                                    <Text size="sm" sx={{ lineHeight: 1 }}>{vector.label}</Text>
                                )
                            ),
                        }))}
                />
            </Container>
            <Space h="lg" />
            <ChatRouteChild
                selectedQuestions={selectedQuestions}
                chatId={chatId}
                messages={messages}
            />
        </>
    );
}

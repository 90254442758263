import { createFileRoute } from '@tanstack/react-router'
import { ManageUsersRoute } from 'src/containers/ManageUsersRoute'

export const Route = createFileRoute('/_layout-nosidebar/manageUsers')({
  component: ManageUsers,
})

export default function ManageUsers() {
  return <ManageUsersRoute />
}

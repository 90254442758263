// TopToolbarCustomActions.tsx
import { useDisclosure } from "@mantine/hooks";
import { useLocation, useNavigate, useSearch } from "@tanstack/react-router";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "src/db";
import { useTab } from "src/contexts/tabContext";
import { Anchor, Button, Drawer, Grid, ScrollArea, Stack, Tabs, Title } from "@mantine/core";
import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Feature } from "flagged";
import { IPublicClientApplication } from "@azure/msal-browser";
import { PdfFocusProvider, PDFViewer } from "@llamaindex/pdf-viewer";
import { getPdfUrlForInlineView } from "./MessageItem";
import { useAccount, useMsal } from "@azure/msal-react";
import { ChatRouteChild } from "src/containers/ChatRoute";
import { MRT_ColumnFiltersState, MRT_TableInstance } from "mantine-react-table";
import { InfiniteData } from "react-query";
import { CHAT_COMPLETIONS_URL } from "src/hooks/useChatCompletion";
import { ViewPdf } from "./PDFViewer/ViewPdf";

export const AsyncPdfViewer = memo(
    ({
        pdfUrl,
        apiKey,
        row,
        instance,
    }: {
        pdfUrl: string;
        apiKey?: string;
        row: string;
        instance: IPublicClientApplication;
    }) => {
        const [pdfUrlWithApiKey, setPdfUrlWithApiKey] = useState<string | null>(
            null,
        );

        useEffect(() => {
            (async () => {
                const url = await getPdfUrlForInlineView(pdfUrl, apiKey, instance);
                setPdfUrlWithApiKey(url);
            })();
        }, [pdfUrl, apiKey, instance]);

        useLayoutEffect(() => {
            if (pdfUrlWithApiKey && document.querySelector(".zoomSelect")?.value === '100%') {
                setTimeout(() => {
                    document.querySelector(".zoomIn")?.click()
                }, 400);
            }
        }, [pdfUrlWithApiKey]);

        if (pdfUrlWithApiKey) {
            return (
                // <PDFViewer
                //     containerClassName="w-[100%] w-full!"
                //     file={{ id: row, url: pdfUrlWithApiKey }}
                // />
                <ViewPdf file={{ id: row, url: pdfUrlWithApiKey }} />
            );
        }
    },
);

const PDFList = memo(
    ({
        rows,
        apiKey,
        instance,
        metadataDocumentsLive,
    }: {
        rows: string[];
        apiKey?: string;
        instance: IPublicClientApplication;
        metadataDocumentsLive: any;
    }) => {
        return (
            <Tabs
                defaultValue={rows[0]}
                orientation="vertical"
                placement="right"
                mah="90vh"
            >
                <Tabs.List>
                    {rows.map((row) => (
                        <Tabs.Tab key={row} value={row}>
                            {row.split("_")[0]}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>
                {rows.map((row) => {
                    const source = metadataDocumentsLive.findLast((doc: any) =>
                        doc._id.includes(row.split("_")[0]),
                    )?.fields.source;
                    const pdfUrl = `${CHAT_COMPLETIONS_URL}${source.startsWith("/") ? "" : "/"}${source}`;
                    return (
                        <Tabs.Panel key={row} value={row} w="auto">
                            <PdfFocusProvider>
                                <div style={{ height: "90vh", width: "100%" }}>
                                    <AsyncPdfViewer
                                        instance={instance}
                                        pdfUrl={pdfUrl}
                                        apiKey={apiKey}
                                        row={row}
                                    />
                                </div>
                            </PdfFocusProvider>
                        </Tabs.Panel>
                    );
                })}
            </Tabs>
        );
    },
);

const TopToolbarCustomActions = ({ table, index, data, columnFilters, isLoading }: {
    table: MRT_TableInstance<any>;
    index: string;
    data: InfiniteData<any> | undefined;
    columnFilters: MRT_ColumnFiltersState;
}) => {
    const MAX_REPORTS = 100;
    const filteredRows = Object.keys(table.getFilteredRowModel().rowsById);
    const hasFilters = Boolean(columnFilters.length);
    let rows =
        hasFilters && filteredRows.length <= MAX_REPORTS
            ? filteredRows.slice(0, MAX_REPORTS)
            : [];
    rows = rows.map((str) => str.split("_")[0]);
    const clickHandler = () => {
        if (hasFilters) {
            open();
            const filteredRowIds = filteredRows.slice(0, MAX_REPORTS).map(id => id.split('_')[0]);
            navigate({ to: `/documents?ids=${filteredRowIds.join(",")}` });
        } else {
            navigate({ to: `/chats?index=${index}` });
        }
    };
    const chatId = rows.join(",");

    const useMessages = (chatId: string) =>
        useLiveQuery(() => {
            if (!chatId) return [];
            return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
        }, [chatId]);

    const messages = useMessages(chatId);
    const [opened, { open, close }] = useDisclosure(false);
    const { setSelectedTab } = useTab();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    const flatData = useMemo(
        () =>
            data?.pages.flatMap((page) =>
                page.hits.hits.map((doc) => ({
                    ...doc,
                    fields: doc._source.metadata,
                })),
            ) ?? [],
        [data],
    );

    useEffect(() => {
        setSelectedTab("field_service_reports");
    }, [opened]);

    return (
        <>
            <Drawer
                styles={{
                    header: {
                        display: "none",
                    },
                    body: {
                        minHeight: "90vh",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                    },
                }}
                opened={opened}
                onClose={() => {
                    navigate({ to: "/documents" });
                    close();
                }}
                title={null}
                size="100%"
            >
                <Grid gutter="0" style={{ height: "100%" }}>
                    <Grid.Col
                        span={5}
                        h="100%"
                        style={{
                            justifyContent: "flex-end",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                        }}
                    >
                        <Stack
                            spacing={0}
                            sx={{
                                position: "fixed",
                                top: ".5rem",
                                left: ".5rem",
                                zIndex: 1000,
                                background: "white",
                                overflowY: "hidden",
                                "max-width": "30%",
                                "text-overflow": "ellipsis",
                                "max-height": "4.5rem",
                            }}
                        >
                            <Anchor
                                c="purple"
                                onClick={() => {
                                    navigate({ to: "/documents" });
                                    close();
                                }}
                            >
                                Back to Document Selection
                            </Anchor>
                            <Title title={rows.join(", ")} bg="white" order={4}>
                                Conversation with {rows.length} Reports:{" "}
                                {rows.join(", ").slice(0, 150)}
                            </Title>
                        </Stack>
                        <ScrollArea styles={{
                            viewport: {
                                alignContent: "end",
                            }
                        }} style={{ height: "100%", alignContent: "bottom" }} pt="100px" sx={{ alignContent: "bottom" }}>
                            <ChatRouteChild
                                isCompact
                                files={rows}
                                // selectedTab={selectedTab}
                                // setSelectedTab={() => null}
                                selectedQuestions={[]}
                                chatId={chatId}
                                metadataFilter={rows.map(row => ({ key: "report_name", value: row }))}
                                messages={messages}
                            />
                        </ScrollArea>
                    </Grid.Col>
                    <Grid.Col span={7} sx={{ overflow: "scroll", maxHeight: "90vh" }}>
                        <PDFList
                            rows={rows}
                            metadataDocumentsLive={flatData ?? []}
                            instance={instance}
                            apiKey={apiKey}
                        />
                    </Grid.Col>
                </Grid>
            </Drawer>
            <Feature name="CHAT_WITH_REPORTS">
                {index === "fsr" ? (
                    <Button
                        loading={isLoading}
                        onClick={clickHandler}
                    >
                        {Object.keys(rows).length > 0
                            ? "Search inside selected reports"
                            : "Start a conversation"}
                    </Button>
                ) : null}
            </Feature>
        </>
    );
};

export default TopToolbarCustomActions;
/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LayoutNosidebarImport } from './routes/_layout-nosidebar'
import { Route as LayoutImport } from './routes/_layout'
import { Route as LayoutIndexImport } from './routes/_layout/index'
import { Route as LayoutChatsImport } from './routes/_layout/chats'
import { Route as LayoutNosidebarUnauthorizedImport } from './routes/_layout-nosidebar/unauthorized'
import { Route as LayoutNosidebarSpotlightImport } from './routes/_layout-nosidebar/spotlight'
import { Route as LayoutNosidebarSalesforceImport } from './routes/_layout-nosidebar/salesforce'
import { Route as LayoutNosidebarManageUsersImport } from './routes/_layout-nosidebar/manageUsers'
import { Route as LayoutNosidebarDocumentsImport } from './routes/_layout-nosidebar/documents'
import { Route as LayoutChatsChatIdImport } from './routes/_layout/chats.$chatId'

// Create/Update Routes

const LayoutNosidebarRoute = LayoutNosidebarImport.update({
  id: '/_layout-nosidebar',
  getParentRoute: () => rootRoute,
} as any)

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const LayoutIndexRoute = LayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutChatsRoute = LayoutChatsImport.update({
  id: '/chats',
  path: '/chats',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutNosidebarUnauthorizedRoute =
  LayoutNosidebarUnauthorizedImport.update({
    id: '/unauthorized',
    path: '/unauthorized',
    getParentRoute: () => LayoutNosidebarRoute,
  } as any)

const LayoutNosidebarSpotlightRoute = LayoutNosidebarSpotlightImport.update({
  id: '/spotlight',
  path: '/spotlight',
  getParentRoute: () => LayoutNosidebarRoute,
} as any)

const LayoutNosidebarSalesforceRoute = LayoutNosidebarSalesforceImport.update({
  id: '/salesforce',
  path: '/salesforce',
  getParentRoute: () => LayoutNosidebarRoute,
} as any)

const LayoutNosidebarManageUsersRoute = LayoutNosidebarManageUsersImport.update(
  {
    id: '/manageUsers',
    path: '/manageUsers',
    getParentRoute: () => LayoutNosidebarRoute,
  } as any,
)

const LayoutNosidebarDocumentsRoute = LayoutNosidebarDocumentsImport.update({
  id: '/documents',
  path: '/documents',
  getParentRoute: () => LayoutNosidebarRoute,
} as any)

const LayoutChatsChatIdRoute = LayoutChatsChatIdImport.update({
  id: '/$chatId',
  path: '/$chatId',
  getParentRoute: () => LayoutChatsRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/_layout-nosidebar': {
      id: '/_layout-nosidebar'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutNosidebarImport
      parentRoute: typeof rootRoute
    }
    '/_layout-nosidebar/documents': {
      id: '/_layout-nosidebar/documents'
      path: '/documents'
      fullPath: '/documents'
      preLoaderRoute: typeof LayoutNosidebarDocumentsImport
      parentRoute: typeof LayoutNosidebarImport
    }
    '/_layout-nosidebar/manageUsers': {
      id: '/_layout-nosidebar/manageUsers'
      path: '/manageUsers'
      fullPath: '/manageUsers'
      preLoaderRoute: typeof LayoutNosidebarManageUsersImport
      parentRoute: typeof LayoutNosidebarImport
    }
    '/_layout-nosidebar/salesforce': {
      id: '/_layout-nosidebar/salesforce'
      path: '/salesforce'
      fullPath: '/salesforce'
      preLoaderRoute: typeof LayoutNosidebarSalesforceImport
      parentRoute: typeof LayoutNosidebarImport
    }
    '/_layout-nosidebar/spotlight': {
      id: '/_layout-nosidebar/spotlight'
      path: '/spotlight'
      fullPath: '/spotlight'
      preLoaderRoute: typeof LayoutNosidebarSpotlightImport
      parentRoute: typeof LayoutNosidebarImport
    }
    '/_layout-nosidebar/unauthorized': {
      id: '/_layout-nosidebar/unauthorized'
      path: '/unauthorized'
      fullPath: '/unauthorized'
      preLoaderRoute: typeof LayoutNosidebarUnauthorizedImport
      parentRoute: typeof LayoutNosidebarImport
    }
    '/_layout/chats': {
      id: '/_layout/chats'
      path: '/chats'
      fullPath: '/chats'
      preLoaderRoute: typeof LayoutChatsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/': {
      id: '/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/chats/$chatId': {
      id: '/_layout/chats/$chatId'
      path: '/$chatId'
      fullPath: '/chats/$chatId'
      preLoaderRoute: typeof LayoutChatsChatIdImport
      parentRoute: typeof LayoutChatsImport
    }
  }
}

// Create and export the route tree

interface LayoutChatsRouteChildren {
  LayoutChatsChatIdRoute: typeof LayoutChatsChatIdRoute
}

const LayoutChatsRouteChildren: LayoutChatsRouteChildren = {
  LayoutChatsChatIdRoute: LayoutChatsChatIdRoute,
}

const LayoutChatsRouteWithChildren = LayoutChatsRoute._addFileChildren(
  LayoutChatsRouteChildren,
)

interface LayoutRouteChildren {
  LayoutChatsRoute: typeof LayoutChatsRouteWithChildren
  LayoutIndexRoute: typeof LayoutIndexRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutChatsRoute: LayoutChatsRouteWithChildren,
  LayoutIndexRoute: LayoutIndexRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

interface LayoutNosidebarRouteChildren {
  LayoutNosidebarDocumentsRoute: typeof LayoutNosidebarDocumentsRoute
  LayoutNosidebarManageUsersRoute: typeof LayoutNosidebarManageUsersRoute
  LayoutNosidebarSalesforceRoute: typeof LayoutNosidebarSalesforceRoute
  LayoutNosidebarSpotlightRoute: typeof LayoutNosidebarSpotlightRoute
  LayoutNosidebarUnauthorizedRoute: typeof LayoutNosidebarUnauthorizedRoute
}

const LayoutNosidebarRouteChildren: LayoutNosidebarRouteChildren = {
  LayoutNosidebarDocumentsRoute: LayoutNosidebarDocumentsRoute,
  LayoutNosidebarManageUsersRoute: LayoutNosidebarManageUsersRoute,
  LayoutNosidebarSalesforceRoute: LayoutNosidebarSalesforceRoute,
  LayoutNosidebarSpotlightRoute: LayoutNosidebarSpotlightRoute,
  LayoutNosidebarUnauthorizedRoute: LayoutNosidebarUnauthorizedRoute,
}

const LayoutNosidebarRouteWithChildren = LayoutNosidebarRoute._addFileChildren(
  LayoutNosidebarRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof LayoutNosidebarRouteWithChildren
  '/documents': typeof LayoutNosidebarDocumentsRoute
  '/manageUsers': typeof LayoutNosidebarManageUsersRoute
  '/salesforce': typeof LayoutNosidebarSalesforceRoute
  '/spotlight': typeof LayoutNosidebarSpotlightRoute
  '/unauthorized': typeof LayoutNosidebarUnauthorizedRoute
  '/chats': typeof LayoutChatsRouteWithChildren
  '/': typeof LayoutIndexRoute
  '/chats/$chatId': typeof LayoutChatsChatIdRoute
}

export interface FileRoutesByTo {
  '': typeof LayoutNosidebarRouteWithChildren
  '/documents': typeof LayoutNosidebarDocumentsRoute
  '/manageUsers': typeof LayoutNosidebarManageUsersRoute
  '/salesforce': typeof LayoutNosidebarSalesforceRoute
  '/spotlight': typeof LayoutNosidebarSpotlightRoute
  '/unauthorized': typeof LayoutNosidebarUnauthorizedRoute
  '/chats': typeof LayoutChatsRouteWithChildren
  '/': typeof LayoutIndexRoute
  '/chats/$chatId': typeof LayoutChatsChatIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/_layout-nosidebar': typeof LayoutNosidebarRouteWithChildren
  '/_layout-nosidebar/documents': typeof LayoutNosidebarDocumentsRoute
  '/_layout-nosidebar/manageUsers': typeof LayoutNosidebarManageUsersRoute
  '/_layout-nosidebar/salesforce': typeof LayoutNosidebarSalesforceRoute
  '/_layout-nosidebar/spotlight': typeof LayoutNosidebarSpotlightRoute
  '/_layout-nosidebar/unauthorized': typeof LayoutNosidebarUnauthorizedRoute
  '/_layout/chats': typeof LayoutChatsRouteWithChildren
  '/_layout/': typeof LayoutIndexRoute
  '/_layout/chats/$chatId': typeof LayoutChatsChatIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/documents'
    | '/manageUsers'
    | '/salesforce'
    | '/spotlight'
    | '/unauthorized'
    | '/chats'
    | '/'
    | '/chats/$chatId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/documents'
    | '/manageUsers'
    | '/salesforce'
    | '/spotlight'
    | '/unauthorized'
    | '/chats'
    | '/'
    | '/chats/$chatId'
  id:
    | '__root__'
    | '/_layout'
    | '/_layout-nosidebar'
    | '/_layout-nosidebar/documents'
    | '/_layout-nosidebar/manageUsers'
    | '/_layout-nosidebar/salesforce'
    | '/_layout-nosidebar/spotlight'
    | '/_layout-nosidebar/unauthorized'
    | '/_layout/chats'
    | '/_layout/'
    | '/_layout/chats/$chatId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren
  LayoutNosidebarRoute: typeof LayoutNosidebarRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
  LayoutNosidebarRoute: LayoutNosidebarRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/_layout-nosidebar"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/chats",
        "/_layout/"
      ]
    },
    "/_layout-nosidebar": {
      "filePath": "_layout-nosidebar.tsx",
      "children": [
        "/_layout-nosidebar/documents",
        "/_layout-nosidebar/manageUsers",
        "/_layout-nosidebar/salesforce",
        "/_layout-nosidebar/spotlight",
        "/_layout-nosidebar/unauthorized"
      ]
    },
    "/_layout-nosidebar/documents": {
      "filePath": "_layout-nosidebar/documents.tsx",
      "parent": "/_layout-nosidebar"
    },
    "/_layout-nosidebar/manageUsers": {
      "filePath": "_layout-nosidebar/manageUsers.tsx",
      "parent": "/_layout-nosidebar"
    },
    "/_layout-nosidebar/salesforce": {
      "filePath": "_layout-nosidebar/salesforce.tsx",
      "parent": "/_layout-nosidebar"
    },
    "/_layout-nosidebar/spotlight": {
      "filePath": "_layout-nosidebar/spotlight.tsx",
      "parent": "/_layout-nosidebar"
    },
    "/_layout-nosidebar/unauthorized": {
      "filePath": "_layout-nosidebar/unauthorized.tsx",
      "parent": "/_layout-nosidebar"
    },
    "/_layout/chats": {
      "filePath": "_layout/chats.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/chats/$chatId"
      ]
    },
    "/_layout/": {
      "filePath": "_layout/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/chats/$chatId": {
      "filePath": "_layout/chats.$chatId.tsx",
      "parent": "/_layout/chats"
    }
  }
}
ROUTE_MANIFEST_END */

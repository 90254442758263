import {
    Box,
    Flex,
    Title,
    useMantineTheme,
} from "@mantine/core";
import {  useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import ManageUsers from "src/components/manageUsers";




export const ManageUsersRoute = () => {
    const navigate = useNavigate();
    const theme = useMantineTheme();
 

    useEffect(() => {
        if (window.location.hash === "#/manageUsers")
            navigate({ to: `/manageUsers` });
    }, []);

    return (
        <Flex p="xl" direction="column" gap="md">
            <Box>
                <ManageUsers />
            </Box>
        </Flex>
    );
};

import type { Tuple } from "@mantine/core";

export const DEFAULT_COLORS = {
	dark: [
		"#262C30",
		"#54646E",
		"#7B919D",
		"#96B0C0",
		"#B8CEDB",
		"#D7E4EE",
		"#FFF",
		"#FFF",
		"#FFF",
		"#FFF",
	],
	gray: [
		"#F2F2F2",
		"#EAEAEA",
		"#DEDEDE",
		"#C0C0C0",
		"#969696",
		"#5F5F5F",
		"#393939",
		"#1B1B1B",
		"#0D0D0D",
		"#000000",
	],
	purple: [
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#641E8C",
		"#4D217A",
		"#1B1534",
	],
	blue: [
		"#E7F5FF",
		"#D0EBFF",
		"#A5D8FF",
		"#74C0FC",
		"#4DABF7",
		"#339AF0",
		"#228BE6",
		"#1C7ED6",
		"#1971C2",
		"#1864AB",
	],
	green: [
		"#05371E",
		"#006C3B",
		"#099053",
		"#27B66D",
		"#14DA79",
		"#00FD79",
		"#FFF",
		"#FFF",
		"#FFF",
		"#FFF",
	],
	yellow: [
		"#FFF9DB",
		"#FFF3BF",
		"#FFEC99",
		"#FFE066",
		"#FFD43B",
		"#FCC419",
		"#FAB005",
		"#F59F00",
		"#F08C00",
		"#E67700",
	],
	brown: [
		"#3D3535",
		"#564646",
		"#7D6766",
		"#8F7C7A",
		"#AF9E9C",
		"#CFC0BE",
		"#FFF",
		"#FFF",
		"#FFF",
		"#FFF",
	],
	red: [
		"#FFF5F5",
		"#FFE3E3",
		"#FFC9C9",
		"#FFA8A8",
		"#FF8787",
		"#FF6B6B",
		"#FA5252",
		"#F03E3E",
		"#E03131",
		"#C92A2A",
	],
	orange: [
		"#FFF4E6",
		"#FFE8CC",
		"#FFD8A8",
		"#FFC078",
		"#FFA94D",
		"#FF922B",
		"#FD7E14",
		"#F76707",
		"#E8590C",
		"#D9480F",
	],
} as Record<string, Tuple<string, 10>>;
